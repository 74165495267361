import { Container } from '@/components/Container';
import { PageHeader } from '@/components/PageHeader';
import { FeatureT, Features } from '@/components/Features';
import { UsersIcon, AcademicCapIcon, ArrowTrendingUpIcon, ArrowPathIcon, ShieldCheckIcon } from '@heroicons/react/24/solid';
import testDrive from '@/images/testdrive.jpg';
const features: Array<FeatureT> = [
  {
    name: 'On-Site Expertise Integration',
    description: `Resourcery TestDrive" introduces a premier enhancement program where Resourcery's highly skilled and knowledgeable professionals with extensive automotive industry backgrounds are deployed directly to your dealership. This on-site expertise integration ensures that your team receives personalized guidance and support to maximize performance`,
    icon: UsersIcon,
  },
  {
    name: 'Ambition Meets Expertise',
    description: `Experience a transformation where ambition meets expertise. TestDrive offers a collaborative platform led by industry experts from Resourcery, empowering your dealership with the insights and strategies needed to surpass expectations and achieve unprecedented success`,
    icon: AcademicCapIcon,
  },
  {
    name: 'Journey Towards Leadership',
    description: ` Embark on a journey towards market leadership with TestDrive. By leveraging the collective knowledge and experience of Resourcery's professionals, your dealership is equipped to rise above the competition and establish itself as a leader in the automotive industry.`,
    icon: ArrowTrendingUpIcon,
  },
  {
    name: 'Collaborative Performance Enhancement',
    description: `TestDrive fosters a culture of collaboration and continuous improvement within your dealership. By working closely with Resourcery's experts, every team member is empowered to contribute to the dealership's success, driving performance to new heights.`,
    icon: ArrowPathIcon,
  },
  {
    name: 'Access to On-Site Support',
    description: `Elevate your dealership's performance with on-site support from Resourcery's industry-leading professionals. With TestDrive, you gain access to tailored solutions and guidance directly at your dealership, ensuring that you have the best resources available to achieve and maintain industry-leading status.`,
    icon: ShieldCheckIcon,
  },
];

export const TestDrive = () => {
  return (
    <div>
      <section
        id="features"
        aria-label="Features for simplifying everyday business tasks"
        className="relative pb-10 lg:pb-20"
        // style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: '50% 25%' }}
      >
        <Container>
          <PageHeader title="Resourcery TestDrive" />
          <div className="relative flex flex-row-reverse">
            <div className="z-[5] w-full sm:w-[50%]">
              <div className="mt-8">
                {`Introducing 'Resourcery TestDrive' – a premier enhancement program, bringing together a team of highly skilled and knowledgeable
              professionals with extensive backgrounds in the automotive industry. This unique initiative is designed to elevate your dealership's
              performance by maximizing your team's potential.`}
              </div>
              <div className="mt-8">
                {`Imagine a world where your dealership's potential is not just met, but exceeded, all at no upfront cost. The Resourcery TestDrive
              program is where ambition meets expertise, offering a collaborative platform led by some of the greatest minds in the automotive
              industry.`}
              </div>
              <img className="my-6 block h-auto w-full rounded-xl object-cover sm:hidden" src={testDrive} alt="Lead Generation" />

              <div className="mt-8">
                {`This initiative is not just about enhancement; it's a journey towards becoming the leader in your market, empowering every team member,
              from the showroom floor to the executive suite. It is tailor-made uniquely to suit your own specific needs. With a focus on
              collaboration and expertise, Resourcery TestDrive commits to guiding your dealership to achieve and maintain industry-leading status.`}
              </div>
              <div className="mt-8">Be the best by getting help from the best!</div>
            </div>
            <div className="absolute left-0 top-0 hidden sm:block sm:w-[70%]">
              <img className="size-full rounded-xl object-cover" src={testDrive} alt="Lead Generation" />
              <div className="absolute inset-0 bg-gradient-to-l from-white to-transparent"></div>
            </div>
          </div>
        </Container>
      </section>
      <Features features={features} />
    </div>
  );
};
