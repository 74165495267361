import { Container } from '@/components/Container';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { PageHeader } from '@/components/PageHeader';
import { ChartPieIcon, UsersIcon, CurrencyDollarIcon, AcademicCapIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/solid';
import { FeatureT, Features } from '@/components/Features';
import girlCar from '@/images/girl_sm.jpg';
import financing from '@/images/financing.jpg';

const features: Array<FeatureT> = [
  {
    name: 'Tailored Expertise Integration',
    description:
      'The Test Pilot program by Resourcery offers a unique approach to integrating seasoned automotive professionals directly into dealership teams. These experts bring a wealth of knowledge and experience, allowing for tailored solutions to specific challenges faced by each dealership, ultimately enhancing overall performance. ',
    icon: UsersIcon,
  },
  {
    name: 'Advanced Lead Generation Strategies',
    description:
      'One of the key focuses of the Test Pilot program is its cutting-edge lead generation strategies. By leveraging data analytics, market trends, and customer behavior insights, Resourcery helps dealerships identify and capture qualified leads more effectively, boosting sales and revenue. ',
    icon: ChartPieIcon,
  },
  {
    name: 'Streamlined F&I Operations',
    description:
      "Resourcery's Test Pilot program brings a fresh perspective to F&I operations, streamlining processes and optimizing backend penetration. From remote financing options to in-house financing solutions, the program assists dealerships in maximizing profitability while ensuring compliance and customer satisfaction.",
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Dynamic Training and Support',
    description:
      'Through the Test Pilot program, Resourcery provides ongoing training and support to dealership staff, empowering them with the latest industry best practices and tools. This continuous learning approach fosters a culture of excellence and innovation within the dealership, driving long-term success. ',
    icon: AcademicCapIcon,
  },
  {
    name: 'Performance Tracking and Optimization',
    description:
      "Resourcery's Test Pilot program doesn't stop at implementation; it includes robust performance tracking and optimization features. By analyzing key metrics and KPIs, the program enables dealerships to identify areas for improvement and make data-driven decisions, ensuring sustained growth and competitiveness in the automotive industry. ",
    icon: ArrowTrendingUpIcon,
  },
];

export const TestPilot = () => {
  return (
    <div>
      <section
        id="features"
        aria-label="Features for simplifying everyday business tasks"
        className="relative pb-6 lg:pb-20"
        // style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: '50% 25%' }}
      >
        <Container>
          <PageHeader title="Resourcery TestPilot " />
          <div className="relative flex flex-col">
            <div className="z-[5] w-full sm:w-[60%]">
              <div className="mt-8  ">
                Resourcery stands at the forefront of automotive innovation with its comprehensive &quot;TestPilot&quot; program, engineered to
                revolutionize F&I (Finance & Insurance) operations and increase back-end penetration within dealerships This program is all about
                connecting the dots between innovative financing solutions and your business needs, ensuring that profitability and customer
                satisfaction aren&apos;t just goals, but realities.
              </div>
              <img className="my-6 block h-auto w-full rounded-xl object-cover sm:hidden" src={girlCar} alt="Lead Generation" />

              <div className="mt-8">
                Resourcery offers financing options that are not just convenient, but also tailored to every customer that walks into your dealership.
                Whether it is remote financing options for the digitally inclined shopper or dealership financing for those who prefer the in-person
                approach, Test Pilot has you covered. And with our strategic approach to lender acquisition, your dealership will have access to
                competitive rates and terms, making you the go-to option for buyers.
              </div>
            </div>
            <div className="absolute right-0 top-0 hidden sm:block sm:w-[60%] md:w-[60%]  lg:w-[40%]">
              <img className="h-auto w-full rounded-xl object-cover" src={girlCar} alt="Lead Generation" />
              <div className="absolute inset-0 bg-gradient-to-r from-white to-transparent"></div>
            </div>
          </div>
          <div className="relative mt-12 flex flex-row-reverse 2xl:mt-6">
            <div className="z-[5] w-full justify-end sm:w-[60%]">
              <div className="">
                But TestPilot doesn&apos;t stop there. It dives deep into the warranty and protection services, enhancing programs that have been
                longing for innovation. Our offering includes personalized guidance from industry professionals who know the ins and outs of
                automotive finance. Resourcery’s team comprises the industry&apos;s most elite finance professionals, who are unmatched in enhancing
                deal backend penetration through specialized F&I training and development. We also take pride in consistently achieving more than
                double the National Automobile Dealership Association’s averages in warranty penetration rates, establishing new benchmarks for
                excellence in the automotive sector.
              </div>
              <img className="my-6 block h-auto w-full rounded-xl object-cover sm:hidden" src={financing} alt="Lead Generation" />

              <div className="mt-8">
                Navigating the complexities of automotive finance can be daunting, but with Test Pilot, you&apos;re never alone. Resourcery stands as
                your strategic partner, employing a consultative approach to propel growth and navigate the complexities of today&apos;s competitive
                environment. Our focus lies in empowering dealerships to overcome obstacles and capitalize on opportunities, ensuring an unparalleled
                experience for your customers.
              </div>
            </div>
            <div className="absolute left-0 top-0 hidden sm:block sm:w-[60%] md:w-[70%]   lg:w-[40%]">
              <img className="size-full rounded-xl object-cover" src={financing} alt="Lead Generation" />
              <div className="absolute inset-0 bg-gradient-to-l from-white to-transparent"></div>
            </div>
          </div>
          <div className="mt-8 text-center">
            Welcome to Test Pilot — where finance meets innovation, and where your dealership&apos;s potential is limitless.
          </div>
        </Container>
      </section>
      <Features features={features} />
    </div>
  );
};
