import { CallToAction } from '../components/CallToAction';
import { Faqs } from '../components/Faqs';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { FeatureT, Features } from '../components/Features';
import { Form } from '../components/Form';
// import { Amplify } from 'aws-amplify';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { useAuthenticator, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
// import config from '../amplifyconfiguration.json';
// Amplify.configure(config);
import { Auth } from 'aws-amplify';

import screenshotContacts from '@/images/screenshots/contacts.png';
import screenshotInventory from '@/images/screenshots/inventory.png';
import screenshotProfitLoss from '@/images/screenshots/profit-loss.png';
import {
  HeartIcon,
  CurrencyDollarIcon,
  LightBulbIcon,
  AcademicCapIcon,
  Cog6ToothIcon,
  MapIcon,
  UserGroupIcon,
  MagnifyingGlassCircleIcon,
} from '@heroicons/react/24/solid';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import config from '../amplifyconfiguration.json';
import { Button } from '@/components/Button';

Amplify.configure(config);

const Admin = () => {
  const { user, signOut } = useAuthenticator(context => [context.user]);

  console.log('user: ', user.username);
  // console.log(Auth.currentSession());

  return (
    <div className="flex min-h-[88vh] flex-col">
      <div className="pt-20"></div>
      <div>
        <Button onClick={() => signOut()}>Sign Out</Button>
      </div>
    </div>
  );
};

const component = {};

export default withAuthenticator(Admin, component);
