export const GetStartedButton = () => {
  return (
    <button
      type="button"
      className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      onClick={() => {
        window.open('https://contact-us-form-submissions-c71b78.zapier.app/submit-form', '_blank');
      }}
    >
      Get Started
    </button>
  );
};
