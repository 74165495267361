import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './routes/App.tsx';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import '@/styles/tailwind.css';
import { TestPilot } from './routes/TestPilot.tsx';
import { TestDrive } from './routes/TestDrive.tsx';
import { Academy } from './routes/Academy.tsx';
import { TalentAcquisition } from './routes/TalentAcquisition.tsx';
import { LeadGeneration } from './routes/LeadGeneration.tsx';
import { Technology } from './routes/Technology.tsx';
import { Library } from './routes/Library.tsx';
import { BusinessFinancialPlanning } from './routes/BusinessFinancialPlanning.tsx';
import { Home } from './routes/Home.tsx';
import Admin from './routes/Admin.tsx';
import '@aws-amplify/ui-react/styles.css';
import { Authenticator } from '@aws-amplify/ui-react';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      // {
      //   path: 'login',
      //   element: <Login />,
      // },
      // {
      //   path: 'signup',
      //   element: <Signup />,
      // },
      {
        path: 'admin',
        element: (
          <Authenticator.Provider>
            <Admin />
          </Authenticator.Provider>
        ),
      },
      {
        path: 'testpilot',
        element: <TestPilot />,
      },
      {
        path: 'testdrive',
        element: <TestDrive />,
      },
      {
        path: 'academy',
        element: <Academy />,
      },
      {
        path: 'talent-acquisition',
        element: <TalentAcquisition />,
      },
      {
        path: 'lead-generation',
        element: <LeadGeneration />,
      },
      {
        path: 'technology',
        element: <Technology />,
      },
      // {
      //   path: 'library',
      //   element: <Library />,
      // },
      {
        path: 'business-financial-planning',
        element: <BusinessFinancialPlanning />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
