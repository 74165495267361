import { Container } from '@/components/Container';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { PageHeader } from '@/components/PageHeader';
import backgroundImage from '@/images/background-faqs.jpg';
import technology from '@/images/technology.jpg';

const Feature = ({ title, description }: { title: string; description: string }) => (
  <div className="mb-8">
    <h2 className="mb-2 text-xl font-bold">{title}</h2>
    <p className="text-gray-700">{description}</p>
  </div>
);

const Step = ({ number, title, description }: { number: number; title: string; description: string }) => (
  <div className="mb-8 flex">
    <div>
      <h3 className="text-lg font-bold">{title}</h3>
      <p className="text-gray-700">{description}</p>
    </div>
  </div>
);

export const Technology = () => {
  return (
    <>
      <div className="pb-10">
        <Container>
          <PageHeader title="Empowering Your Dealership with Cutting EdgeTechnology" />
          <div className="mt-8">
            {`At Resourcery, we're not just participants in the digital transformation of the automotive industry; we're pioneers. With extensive experience in deploying technology to revolutionize dealership operations, our journey has been defined by innovation, efficiency, and success.`}
          </div>
          <div className="mt-8">
            <div className="relative flex flex-row-reverse">
              <div className="z-[5] mb-12 lg:w-[60%]">
                <Feature
                  title="Customer Relationship Management (CRM)"
                  description={`Our journey in revolutionizing customer interactions through CRM systems has proven indispensable. By leveraging CRM systems such as Salesforce and PBS Systems, we've equipped dealerships with the tools to foster deeper customer connections, streamline sales cycles, and enhance loyalty.`}
                />
                <Feature
                  title="Website Development"
                  description={`Specializing in WordPress, we've mastered the art of crafting compelling, user-centric websites. Our websites not only captivate visitors but also convert them into loyal customers, thanks to our deep understanding of the automotive industry's needs and high-quality user experience.`}
                />
                <img className="my-6 block h-auto w-full rounded-xl object-cover lg:hidden" src={technology} alt="Lead Generation" />
              </div>
              <div className="absolute left-0 top-0 hidden sm:h-[100%] lg:block ">
                <img className="size-full rounded-xl object-cover" src={technology} alt="Lead Generation" />
                <div className="absolute inset-0 bg-gradient-to-l from-white to-transparent"></div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div
        id="features"
        aria-label="Features for simplifying everyday business tasks"
        className="relative min-h-[88vh] pb-24"
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: '50% 25%' }}
      >
        <Container>
          <div className="container ">
            <div className="mb-12">
              <div>
                <Feature
                  title="Artificial Intelligence (AI)"
                  description={`Utilizing cutting-edge AI technology, we've revolutionized customer service and operational efficiency in the automotive sector. Our AI solutions deliver personalized customer interactions and provide actionable insights, setting unprecedented standards for responsiveness and personalization within the industry. `}
                />
                <Feature
                  title="Lead Generation Platforms"
                  description="Utilizing a variety of platforms, we've refined our approach to attracting and nurturing leads. Our targeted strategies ensure that potential customers are engaged effectively, driving sales and fostering growth."
                />
                <Feature
                  title="Electronic Vehicle Registration (EVR)"
                  description={`Through EVR solutions like Dealertrack, we've streamlined the registration and titling process, significantly reducing paperwork and enhancing customer satisfaction at the point of sale.`}
                />
              </div>
              <div>
                {` Leveraging our deep knowledge and experience across these technologies, Resourcery is equipped to elevate your dealership's
                  operations, customer experience, and market position. Let us guide you through the digital era, where efficiency meets innovation.`}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
