import { CallToAction } from '../components/CallToAction';
import { Faqs } from '../components/Faqs';
import { FeatureT, Features } from '../components/Features';
import { Form } from '../components/Form';
// import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
// import config from '../amplifyconfiguration.json';
// Amplify.configure(config);

import screenshotContacts from '@/images/screenshots/contacts.png';
import screenshotInventory from '@/images/screenshots/inventory.png';
import screenshotProfitLoss from '@/images/screenshots/profit-loss.png';
import {
  HeartIcon,
  CurrencyDollarIcon,
  LightBulbIcon,
  AcademicCapIcon,
  Cog6ToothIcon,
  MapIcon,
  UserGroupIcon,
  MagnifyingGlassCircleIcon,
} from '@heroicons/react/24/solid';

const features: Array<FeatureT> = [
  {
    name: 'Customer Experience Enhancement',
    summary: 'Elevating Client Satisfaction',
    description:
      'We believe in creating unforgettable experiences for every customer. Our strategies revolve around personalization and service excellence, ensuring that your clients not only return but also become advocates for your brand.',
    image: screenshotProfitLoss,
    icon: HeartIcon,
  },
  {
    name: 'Sales Process Optimization',
    summary: 'Streamlining Sales Success',
    description:
      'Transform your sales journey into an efficient and effective process. Our team helps you implement best practices to streamline operations, reduce sales cycle time, and significantly increase closure rates.',
    image: screenshotInventory,
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Training and Development',
    summary: 'Empowering Skills Growth',
    description:
      'Empower your team with the skills and knowledge they need to excel. Our bespoke training programs cover the latest trends and techniques in automotive sales, customer service, and technical skills, fostering a culture of continuous learning and development.',
    image: screenshotContacts,
    icon: LightBulbIcon,
  },
  {
    name: 'Operational Efficiency Enhancement',
    summary: 'Maximizing Operational Efficacy',
    description:
      'Efficiency is key to profitability. We analyze and revamp your operational workflows, removing bottlenecks and implementing lean management principles to optimize performance and reduce costs.',
    image: screenshotContacts,
    icon: Cog6ToothIcon,
  },
  {
    name: 'Performance Monitoring and Analytics',
    summary: 'Data-Driven Insights',
    description:
      'Data drives decisions. Our advanced analytics services help you monitor performance metrics, providing actionable insights to steer your dealership towards its financial and operational goals.',
    image: screenshotContacts,
    icon: AcademicCapIcon,
  },
  {
    name: 'Strategic Planning and Market Positioning',
    summary: 'Strategizing Market Dominance',
    description:
      'Navigate the competitive automotive market with confidence. We assist in developing a clear, strategic road map, positioning your dealership to capitalize on market opportunities and trends effectively.',
    image: screenshotContacts,
    icon: MapIcon,
  },
  {
    name: 'Leadership skills and succession planning',
    summary: 'Cultivating Future Leaders',
    description: `Building tomorrow's leaders today. Our leadership development programs are tailored to cultivate high-potential individuals within your organization, ensuring a robust succession pipeline for sustained business success.`,
    image: screenshotContacts,
    icon: UserGroupIcon,
  },
  {
    name: 'Lead Generation Mastery',
    summary: 'Cultivating Future Leaders',
    description: `
    In auto dealership consulting, lead generation is key for growth and customer relations. We specialize in crafting strategies to optimize marketing, utilize digital platforms, and form alliances. Our tactics include targeted ads, SEO, and social media engagement to attract and convert buyers. We also stress nurturing partnerships, attending events, and providing great experiences for a steady flow of quality leads, driving dealership success.`,
    image: screenshotContacts,
    icon: MagnifyingGlassCircleIcon,
  },
];

export const Home = () => {
  return (
    <div>
      <CallToAction />
      <Features features={features} />
      <Form />
      <Faqs />
    </div>
  );
};
