import { Container } from '@/components/Container';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { PageHeader } from '@/components/PageHeader';
import backgroundImage from '@/images/background-faqs.jpg';
import talentAcqu from '@/images/talentacqu.jpg';
const Feature = ({ title, description }: { title: string; description: string }) => (
  <div className="mb-8">
    <h2 className="mb-2 text-xl font-bold">{title}</h2>
    <p className="text-gray-700">{description}</p>
  </div>
);

const Step = ({ number, title, description }: { number: number; title: string; description: string }) => (
  <div className="mb-8 flex">
    <div>
      <h3 className="text-lg font-bold">{title}</h3>
      <p className="text-gray-700">{description}</p>
    </div>
  </div>
);

export const TalentAcquisition = () => {
  return (
    <div>
      <section
        id="features"
        aria-label="Features for simplifying everyday business tasks"
        className="relative"
        // style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: '50% 25%' }}
      >
        <Container>
          <PageHeader title="Recruitment: Talent and Acquisition" />
          <div className="relative flex flex-row">
            {`At Resourcery, we understand that the success of your dealership hinges on having the right talent in place. Our Recruitment: Talent and Acquisition service is designed to assist you in finding and acquiring top-tier professionals who will drive your dealership's growth and success. `}
          </div>
          <div className="mt-8">
            <h1 className="z-[5] mb-8  text-3xl font-bold">Why Choose Resourcery for Recruitment?</h1>
            <div className="container relative flex flex-col ">
              <div className="z-[5] w-full sm:w-[55%]">
                <div className="z-[5] mb-12 ">
                  <Feature
                    title="Strategic Talent Acquisition"
                    description="We employ a strategic approach to talent acquisition, leveraging our industry expertise and extensive network to identify and attract the best candidates for your dealership's specific needs."
                  />
                  <Feature
                    title="Customized Recruitment Solutions"
                    description="Every dealership is unique, and so are its recruitment needs. We tailor our recruitment solutions to align with your dealership's culture, values, and objectives, ensuring a perfect fit for both the role and your team."
                  />
                  <Feature
                    title="Streamlined Hiring Process"
                    description="We streamline the hiring process, saving you time and resources while ensuring that you have access to a pool of qualified candidates who are ready to make an immediate impact."
                  />
                  <img className="my-6 block h-auto w-full rounded-xl object-cover sm:hidden" src={talentAcqu} alt="Lead Generation" />
                </div>
              </div>
              <div className="absolute right-0 top-0 hidden sm:block sm:w-[60%]">
                <img className="size-full rounded-xl object-cover" src={talentAcqu} alt="Lead Generation" />
                <div className="absolute inset-0 bg-gradient-to-r from-white to-transparent"></div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section
        id="features"
        aria-label="Features for simplifying everyday business tasks"
        className="relative py-14  sm:pb-20 lg:pb-20"
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: '50% 25%' }}
      >
        <Container>
          <h2 className="mb-4 text-2xl font-bold">Our Recruitment Process</h2>
          <Step
            number={1}
            title="Needs Assessment"
            description="We start by assessing your dealership's staffing needs, considering factors such as growth objectives, departmental requirements, and cultural fit."
          />
          <Step
            number={2}
            title="Candidate Sourcing"
            description="Leveraging our extensive network and industry connections, we identify and engage top-tier talent that matches your specific criteria."
          />
          <Step
            number={3}
            title="Screening and Evaluation"
            description="Our team conducts thorough screening and evaluation processes to ensure that candidates not only meet the required qualifications but also align with your dealership's values and culture."
          />
          <Step
            number={4}
            title="Interview Coordination"
            description="We coordinate interviews between your dealership's hiring managers and the shortlisted candidates, providing support and guidance throughout the process."
          />
          <Step
            number={5}
            title="Offer Negotiation and Onboarding"
            description="Once a candidate is selected, we assist with offer negotiation and facilitate a smooth onboarding process to ensure a seamless transition into the role."
          />

          <p className="mt-8">
            Don't leave your dealership's success to chance. Get in touch with Resourcery to get your firm’s recruitment process simplified to just
            one step. Contact us today to learn more about how we can assist you in finding the right talent to propel your dealership forward.
          </p>
        </Container>
      </section>
      {/* <Features features={features} /> */}
    </div>
  );
};
