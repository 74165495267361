import GetStarted from '@/ui-components/GetStarted';
import { Container } from './Container';
import ZapierForm from 'react-zapier-form';
import { GetStartedButton } from './GetStartedButton';

export const Form = () => {
  return (
    <section id="get-started" aria-labelledby="get-started" className="relative  overflow-hidden bg-slate-50 py-14 pt-20 ">
      <Container>
        <div className="mx-auto  lg:mx-0">
          <h2 className="text-3xl tracking-tight text-slate-900 sm:text-4xl">Get Started on Your Path to Automotive Excellence</h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Take the first step towards transforming your dealership with our tailored solutions. Simply provide your name, dealership name, email,
            phone number, address, and title in the form below. We&apos;ll reach out with personalized insights and strategies to elevate your
            operations, sales, and customer service. Join us in driving success in the competitive automotive market!
          </p>
        </div>

        {/* <GetStarted /> */}
        {/* <ZapierForm action="https://hooks.zapier.com/hooks/catch/18315815/3xmagno/">
          {({ error, loading, success }) => {
            return (
              <div>
                {!success && !loading && (
                  <div>
                    <div className="space-y-12">
                      <div className=" border-gray-900/10 ">
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div className="sm:col-span-3 lg:col-span-1">
                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                              First name
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3 lg:col-span-1">
                            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                              Last name
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3 lg:col-span-2">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                              Email address
                            </label>
                            <div className="mt-2">
                              <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-3 lg:col-span-1">
                            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                              Phone number
                            </label>
                            <div className="mt-2">
                              <input
                                id="phone"
                                name="phone"
                                type="phone"
                                autoComplete="phone"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3 lg:col-span-2">
                            <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                              Street address
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="street-address"
                                id="street-address"
                                autoComplete="street-address"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3 lg:col-span-1">
                            <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                              City
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="city"
                                id="city"
                                autoComplete="address-level2"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3 lg:col-span-1">
                            <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                              Province
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="region"
                                id="region"
                                autoComplete="address-level1"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3 lg:col-span-1">
                            <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                              Postal code
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="postal-code"
                                id="postal-code"
                                autoComplete="postal-code"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-full lg:col-span-5">
                            <label htmlFor="questions" className="block text-sm font-medium leading-6 text-gray-900">
                              Any Questions?
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="questions"
                                id="questions"
                                autoComplete="questions"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="hidden sm:col-span-2">
                            <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">
                              Date
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="date"
                                id="date"
                                value={new Date().toISOString()}
                                autoComplete="date"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-6 flex items-center justify-start gap-x-6">
                      <button
                        type="submit"
                        className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
                {loading && <div>Loading...</div>}
                {error && <div>Something went wrong. Please try again later.</div>}
                {success && (
                  <div>
                    <br />
                    Thank you for contacting us!
                  </div>
                )}
              </div>
            );
          }}
        </ZapierForm> */}
        <div className="pt-4">
          <GetStartedButton />
        </div>
      </Container>
    </section>
  );
};
