import { Container } from '@/components/Container';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { PageHeader } from '@/components/PageHeader';
import backgroundImage from '@/images/background-faqs.jpg';
import leadGen from '@/images/leadgen.jpg';

const Feature = ({ title, description }: { title: string; description: string }) => (
  <div className="mb-8">
    <h2 className="mb-2 text-xl font-bold">{title}</h2>
    <p className="text-gray-700">{description}</p>
  </div>
);

const Step = ({ number, title, description }: { number: number; title: string; description: string }) => (
  <div className="mb-8 flex">
    <div>
      <h3 className="text-lg font-bold">{title}</h3>
      <p className="text-gray-700">{description}</p>
    </div>
  </div>
);

export const LeadGeneration = () => {
  return (
    <div>
      <section
        id="features"
        aria-label="Features for simplifying everyday business tasks"
        className="relative "
        // style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: '50% 25%' }}
      >
        <Container>
          <PageHeader title="Elevate Your Dealership with Premier Lead Generation Services" />
          <div className="mt-8">
            {`At Resourcery, we specialize in propelling dealerships to the forefront of their market through strategic lead generation initiatives. In an industry where competition is fierce, we drive dealerships like yours to the forefront of the market. Our experience with innovative solutions and technology doesn’t just attract leads—it transforms them into loyal customers and drives unprecedented growth.`}
          </div>
          <div className="mt-8">
            <h1 className="mb-2 flex flex-col text-3xl font-bold">Why Choose Us?</h1>
            <div className="mb-6">{`At Resourcery, we stand at the intersection of technological innovation and tailored marketing strategies. Here’s what sets us apart: `}</div>
            <div className="relative flex flex-col md:flex-row">
              <img className="mb-6 block h-auto w-full rounded-xl object-cover sm:hidden" src={leadGen} alt="Lead Generation" />
              <div className="relative z-10 md:w-full">
                <Feature
                  title="Personalized Email Campaigns"
                  description="Crafted to speak directly to your potential customers, keeping your dealership top of mind. "
                />
                <Feature
                  title="SEO-Optimized Content"
                  description="Ensures your dealership ranks higher in search results, attracting more organic leads. "
                />
                <Feature
                  title="Data-Driven Decisions"
                  description="Our strategies are informed by comprehensive market analysis, targeting your ideal customers effectively."
                />
                <Feature
                  title="Bespoke Solutions"
                  description="From sales to service, we offer solutions designed around your dealership's unique needs. "
                />
                <Feature title="Expert Guidance" description="Benefit from the wisdom of industry veterans committed to your success. " />
                <Feature
                  title="Innovative Programs"
                  description="TestDrive and beyond, our programs set the stage for your dealership to dominate the market. "
                />
              </div>
              <div className="absolute right-0 hidden sm:block md:w-[75%]">
                <img className="h-auto w-full rounded-xl object-cover" src={leadGen} alt="Lead Generation" />
                <div className="absolute inset-0 bg-gradient-to-r from-white to-transparent"></div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section
        id="features"
        aria-label="Features for simplifying everyday business tasks"
        className="relative py-8 "
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: '50% 25%' }}
      >
        <Container>
          <h2 className="mb-4 text-2xl font-bold">Our Proven Approach </h2>

          <Step
            number={1}
            title="Understanding Your Audience"
            description="Before anything else, we immerse ourselves in understanding the unique landscape of your operations and the behaviors of potential car buyers. This foundational step ensures our strategies are precisely targeted.  
            "
          />
          <Step
            number={2}
            title="Persona Creation 
            "
            description="We craft detailed buyer personas to create a more targeted and engaging experience that resonates with your unique audience, ultimately driving higher conversion rates and customer satisfaction. 
            "
          />
          <Step
            number={3}
            title="Tailored Strategies 
            "
            description="We leverage advanced data analytics to craft marketing strategies uniquely suited to engage and captivate your target audience. Each campaign is a blueprint for success, designed to resonate deeply with potential buyers.  
            "
          />
          <Step
            number={4}
            title="Engagement and Conversion 
            "
            description="Our content isn’t just engaging—it’s strategic. We create compelling narratives that pique interest and drive action, turning passive interest into active inquiries.  
            "
          />
          <Step
            number={5}
            title="Nurturing Leads 
            "
            description="Our relationship with leads doesn’t end at the first click. We nurture them through a carefully structured sales funnel, enhancing the likelihood of conversion at every step. 
            "
          />
        </Container>
      </section>
      <Container>
        <h2 className="my-4 text-2xl font-bold">Ready to Transform Your Sales?</h2>

        <p className="mt-8">
          {`The journey to transforming your dealership begins now. Don’t let potential leads and sales slip through your fingers. Start your journey to success with us. Submit your details, and our expert team will be in touch to explore how we can elevate your dealership to new heights. `}
        </p>
      </Container>
    </div>
  );
};
