import { Container } from '@/components/Container';
import { PageHeader } from '@/components/PageHeader';
import backgroundImage from '@/images/background-faqs.jpg';
import stairMan from '@/images/stairman.jpg';
const Feature = ({ title, description }: { title: string; description: string }) => (
  <div className="mb-8">
    <h2 className="mb-2 text-xl font-bold">{title}</h2>
    <p className="text-gray-700">{description}</p>
  </div>
);

const Step = ({ number, title, description }: { number: number; title: string; description: string }) => (
  <div className="mb-8 flex">
    <div>
      <h3 className="text-lg font-bold">{title}</h3>
      <p className="text-gray-700">{description}</p>
    </div>
  </div>
);

export const BusinessFinancialPlanning = () => {
  return (
    <div className="flex flex-col">
      <Container>
        <PageHeader title="Business & Financial Planning" />
        <div className="mt-8">
          {`Resourcery specializes in providing comprehensive Business & Financial Planning services tailored specifically for the automotive industry. With our deep understanding of the sector's intricacies and challenges, we collaborate closely with clients to develop strategic plans that optimize financial performance and drive sustainable growth. By analyzing market trends, identifying opportunities, and mitigating risks, we help automotive businesses navigate complexities and achieve their long-term objectives. `}
        </div>
        <div className="mt-8">
          {`Our expertise spans budgeting, forecasting, investment analysis, and more, ensuring that our clients are equipped to make informed decisions and thrive in today's competitive landscape. `}
        </div>
        <img className="my-6 block h-auto w-full rounded-xl object-cover sm:hidden" src={stairMan} alt="Lead Generation" />

        <div className="relative mb-16  flex flex-col ">
          <div className="z-[5] w-full sm:w-[50%]">
            <Feature
              title="Strategic Alignment"
              description={`Our Business & Financial Planning services ensure alignment between business goals and financial strategies, enabling automotive businesses to pursue growth opportunities with confidence. `}
            />
            <Feature
              title="Risk Mitigation"
              description={` Through thorough analysis and planning, we help mitigate financial risks inherent in the automotive industry, safeguarding businesses against market volatility and economic uncertainties. `}
            />
          </div>
          <div className="absolute right-0 top-0 hidden sm:block sm:w-[60%] md:w-[70%]   lg:w-[40%]">
            <img className="size-full rounded-xl object-cover" src={stairMan} alt="Lead Generation" />
            <div className="absolute inset-0 bg-gradient-to-r from-white to-transparent"></div>
          </div>
        </div>
      </Container>
      <div
        id="features"
        aria-label="Features for simplifying everyday business tasks"
        className="relative mt-6 grow"
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: '50% 25%' }}
      >
        <Container>
          <div className="mt-8">
            <div className="container ">
              <div className="mb-12">
                <Feature
                  title="Resource Optimization"
                  description={` Our tailored plans optimize resource allocation, maximizing operational efficiency and profitability for automotive businesses of all sizes. `}
                />
                <Feature
                  title="Long-Term Sustainability"
                  description="By focusing on long-term financial health and growth, we empower automotive businesses to build resilience and adaptability, ensuring sustained success amidst evolving market conditions. "
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
