import { Container } from '@/components/Container';
import { Logo } from '@/components/Logo';

export function Footer() {
  return (
    <footer className="mt-auto bg-slate-50">
      <Container>
        <div>
          <div className="flex justify-center">
            <Logo />
          </div>
        </div>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-4 sm:flex-row-reverse sm:justify-between">
          <p className="mt-2 text-sm text-slate-500 sm:mt-0">Copyright &copy; {new Date().getFullYear()} Resourcery. All rights reserved.</p>
        </div>
      </Container>
    </footer>
  );
}
