import { Container } from '@/components/Container';
import { PageHeader } from '@/components/PageHeader';
import { UsersIcon, UserPlusIcon, BanknotesIcon, Cog6ToothIcon, BookOpenIcon } from '@heroicons/react/24/solid';
import { FeatureT, Features } from '@/components/Features';
import academy from '@/images/academy.jpg';

const features: Array<FeatureT> = [
  {
    name: 'Sales Person Onboarding Program',
    description: `This program equips new sales staff with the skills and knowledge to excel on the showroom floor. From mastering product details to perfecting sales techniques, participants will learn how to engage customers effectively and close deals confidently.  Through immersive training sessions, participants learn the  art of negotiation, how to conduct value-based conversations, and the nuances of customer psychology.`,
    icon: UserPlusIcon,
  },
  {
    name: 'Finance Manager Onboarding Program',
    description: `This all-encompassing program provides finance managers with vital knowledge and tools, covering areas such as loan processing, compliance, warranty & insurance coverage positioning, and effective customer communication. It equips them to adeptly navigate the intricate financial facets of automotive sales. From understanding various financing options and regulations to mastering the art of presenting these options to customers, the course ensures that finance managers can enhance customer trust and satisfaction while optimizing dealership revenue. `,
    icon: BanknotesIcon,
  },
  {
    name: 'Service & Operations Onboarding Program',
    description: `Geared towards service department personnel, this program places a strong emphasis on cultivating both technical prowess and a commitment to exceptional customer service. Participants delve into cutting-edge automotive technologies, master troubleshooting strategies, and discover the art of delivering unparalleled service experiences.  `,
    icon: Cog6ToothIcon,
  },
  {
    name: 'Management Onboarding Program:',
    description: `New managers are introduced to the leadership skills necessary for driving dealership success. This program covers strategic planning, team management, and operational efficiency. Managers learn how to inspire and lead their teams, develop effective business strategies, and create an environment that fosters growth and enhanced profitability. `,
    icon: UsersIcon,
  },
  {
    name: 'Catalogue of Learning Aids',
    description: `Beyond initial onboarding, Resourcery Academy offers an extensive library of learning materials to support continuous development. This includes access to the latest industry research, technology updates, and management best practices, ensuring that all team members remain at the forefront of automotive innovation.`,
    icon: BookOpenIcon,
  },
];

export const Academy = () => {
  return (
    <div>
      <section
        id="features"
        aria-label="Features for simplifying everyday business tasks"
        className="relative lg:pb-20"
        // style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: '50% 25%' }}
      >
        <Container>
          <PageHeader title="Resourcery Academy " />
          <div className="relative my-12 flex flex-col">
            <div className="z-[5] w-full sm:w-[50%]">
              <div>
                {`Welcome to Resourcery Academy, the cornerstone of excellence for your dealership's team development. Our Academy offers comprehensive onboarding programs for various roles within your dealership, including sales personnel, finance managers, service staff, and management. `}
              </div>
              <div className="mt-8">{`Each program is meticulously designed to equip new team members with the skills and knowledge needed for success in their respective areas. Furthermore, our robust catalogue of learning aids ensures continuous professional growth and mastery of the latest industry practices. Embrace the future of automotive excellence with Resourcery Academy, where we build the leaders of tomorrow, today. `}</div>
            </div>
            <div className="absolute right-0 top-0 hidden sm:block sm:w-[100%] md:w-[50%]   lg:w-[40%]">
              <img className="size-full rounded-xl object-cover" src={academy} alt="Lead Generation" />
              <div className="absolute inset-0 bg-gradient-to-r from-white to-transparent"></div>
            </div>
          </div>
          <img className="my-6 block h-auto w-full rounded-xl object-cover sm:hidden" src={academy} alt="Lead Generation" />
        </Container>
      </section>
      <Features features={features} />
    </div>
  );
};
