import image from '@/images/showroomhr.webp';
import { Hero } from './Hero';
import { Hero2 } from './Hero2';
import { Hero3 } from './Hero3';
import { useEffect, useState } from 'react';

export function CallToAction() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextHero = () => {
    setCurrentIndex((currentIndex + 1) % heroComponents.length);
  };

  const prevHero = () => {
    setCurrentIndex((currentIndex - 1 + heroComponents.length) % heroComponents.length);
  };

  const seeMore = (
    <div className="pt-4 text-center text-lg font-bold">
      <button onClick={nextHero}>See more</button>
    </div>
  );

  const heroComponents = [<Hero key="hero1" seeMore={seeMore} />, <Hero2 key="hero2" seeMore={seeMore} />, <Hero3 key="hero3" seeMore={seeMore} />];

  return (
    <div>
      <section id="get-started-today" className="relative overflow-hidden">
        <div className="h-dvh bg-cover bg-fixed" style={{ backgroundImage: `url(${image})`, backgroundPosition: 'center' }}>
          {heroComponents[currentIndex]}
        </div>
      </section>
    </div>
  );
}
