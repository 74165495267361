import { Image } from '@chakra-ui/image';
import Resourcery from '@/images/Resourcery Logo.png';

export function Logo() {
  return (
    <>
      <Image src={Resourcery} width={120} height={'80px'} style={{ objectFit: 'contain', objectPosition: 'center' }} />
    </>
  );
}
