import Image from 'next/image';

import { Container } from '@/components/Container';
import backgroundImage from '@/images/background-faqs.jpg';
import { Contacts } from './Contacts';

const faqs = [
  [
    {
      question: 'How does your approach to Customer Experience Enhancement impact long-term customer relations?',
      answer:
        'Our focus on personalization and service excellence transforms customer interactions into unforgettable experiences. This approach not only encourages repeat business but also turns your clients into brand advocates, positively impacting long-term customer relations and brand loyalty.',
    },
  ],
  [
    {
      question: 'Can you explain how Sales Process Optimization improves our sales outcomes?',
      answer:
        'By streamlining your sales journey, we aim to make the process more efficient and effective. Our strategies include implementing best practices that reduce the sales cycle time and increase closure rates, leading to more successful sales outcomes and improved profitability.',
    },
  ],
  [
    {
      question: 'What are the key benefits of your Training and Development programs for automotive sales teams?',
      answer:
        'Our bespoke training programs are designed to equip your team with the latest trends and techniques in automotive sales, customer service, and technical skills. This not only enhances their current performance but also fosters a culture of continuous learning and development, ensuring they stay ahead in a competitive market.',
    },
  ],
  [
    {
      question: "How does Performance Monitoring and Analytics contribute to our dealership's success?",
      answer:
        'Our advanced analytics services provide you with deep insights into performance metrics, enabling data-driven decision-making. This strategic approach helps you monitor and optimize various aspects of your dealership, steering it towards achieving both financial and operational goals more effectively.',
    },
  ],
];

export function Faqs() {
  return (
    <>
      <section aria-labelledby="faq-title" className="relative overflow-hidden bg-slate-50 py-14 pt-12 sm:pb-20 sm:pt-12 lg:pb-20">
        <Image
          className="absolute left-1/2 top-0 max-w-none -translate-y-[0%] translate-x-[-50%]"
          src={backgroundImage}
          alt=""
          width={1558}
          height={946}
          unoptimized
        />
        <Container className="relative">
          {/* <Contacts /> */}
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 id="faq" className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
              Frequently asked questions
            </h2>
            <p className="mt-4 text-lg tracking-tight text-slate-700">
              If you can’t find what you’re looking for, email our support team and someone will get back to you.
            </p>
          </div>
          <ul role="list" className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
            {faqs.map((column, columnIndex) => (
              <li key={columnIndex}>
                <ul role="list" className="flex flex-col gap-y-8">
                  {column.map((faq, faqIndex) => (
                    <li key={faqIndex}>
                      <h3 className="font-display text-lg leading-7 text-slate-900">{faq.question}</h3>
                      <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </Container>
      </section>
    </>
  );
}
