import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';

import { NavLink } from '@/components/NavLink';
import { Logo } from './Logo';
import { Link, useLocation } from 'react-router-dom';

function MobileNavLink({ href, children }: { href: string; children: React.ReactNode }) {
  const location = useLocation();

  const isActive = location.pathname === href;
  // const isActive = true;
  return (
    <Popover.Button as={Link} to={href} className={`z-20 block w-full p-2 ${isActive ? 'bg-slate-100 text-slate-900' : ''}`}>
      {children}
    </Popover.Button>
  );
}

function MobileNavIcon({ open }: { open: boolean }) {
  return (
    <svg aria-hidden="true" className="size-3.5 overflow-visible stroke-slate-700" fill="none" strokeWidth={2} strokeLinecap="round">
      <path d="M0 1H14M0 7H14M0 13H14" className={clsx('origin-center transition', open && 'scale-90 opacity-0')} />
      <path d="M2 2L12 12M12 2L2 12" className={clsx('origin-center transition', !open && 'scale-90 opacity-0')} />
    </svg>
  );
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex size-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full z-20 mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <MobileNavLink href="/testpilot">TestPilot</MobileNavLink>
            <MobileNavLink href="/testdrive">TestDrive</MobileNavLink>
            <MobileNavLink href="/academy">Resourcery Academy</MobileNavLink>
            <MobileNavLink href="/talent-acquisition">Talent & Acquisition</MobileNavLink>
            <MobileNavLink href="/lead-generation">Lead Generation</MobileNavLink>
            <MobileNavLink href="/technology">Technology</MobileNavLink>
            {/* <MobileNavLink href="/library">Library</MobileNavLink> */}
            <MobileNavLink href="/business-financial-planning">Business & Financial Planning</MobileNavLink>
            {/* <MobileNavLink href="#features">Features</MobileNavLink>
            <MobileNavLink href="#get-started">Get Started</MobileNavLink>
            <MobileNavLink href="#team">Our Team</MobileNavLink>
            <MobileNavLink href="#faq">Frequently Asked Questions</MobileNavLink> */}
            {/* <MobileNavLink href="#pricing">Pricing</MobileNavLink> */}
            <hr className="m-2 border-slate-300/40" />
            {/* <MobileNavLink href="/login">Sign in</MobileNavLink> */}
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

function CustomContainer({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
  return <div className={clsx('mx-auto max-w-full px-6 xl:max-w-7xl xl:px-10', className)} {...props} />;
}

export function Header() {
  return (
    <div className="fixed z-10 w-full">
      <div className="relative">
        <div className="z-10 flex w-full flex-col  bg-white">
          <CustomContainer className="w-full">
            <nav className=" flex w-full justify-between ">
              <div className="flex items-center  md:gap-x-6">
                <Link href="/" aria-label="Home">
                  <Logo />
                </Link>
                <div className="hidden overflow-x-auto md:gap-x-6 lg:flex">
                  <NavLink href="/testpilot">
                    <div className="sm:text-sm xl:text-lg">TestPilot</div>
                  </NavLink>
                  <NavLink href="/testdrive">
                    <div className="sm:text-sm xl:text-lg">TestDrive</div>
                  </NavLink>
                  <NavLink href="/academy">
                    <div className="sm:text-sm xl:text-lg">Resourcery Academy</div>
                  </NavLink>
                  <NavLink href="/talent-acquisition">
                    <div className="sm:text-sm xl:text-lg">Talent & Acquisition</div>
                  </NavLink>
                  <NavLink href="/lead-generation">
                    <div className="sm:text-sm xl:text-lg">Lead Generation</div>
                  </NavLink>
                  <NavLink href="/technology">
                    <div className="sm:text-sm xl:text-lg">Technology</div>
                  </NavLink>
                  {/* <NavLink href="/library">
                    <div className="sm:text-sm xl:text-lg">Library</div>
                  </NavLink> */}
                  <NavLink href="/business-financial-planning">
                    <div className="sm:text-sm xl:text-lg">Business & Financial Planning</div>
                  </NavLink>
                </div>
              </div>
              <div className="flex items-center gap-x-5 md:gap-x-8">
                <div className="hidden sm:block">{/* <NavLink href="/login">Sign in</NavLink> */}</div>
                <div className="-mr-1 lg:hidden">
                  <MobileNavigation />
                </div>
              </div>
            </nav>
          </CustomContainer>
        </div>
      </div>
    </div>
  );
}
