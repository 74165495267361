import { useLocation, NavLink as NL } from 'react-router-dom';

export function NavLink({ href, children, className }: { href: string; children: React.ReactNode; className?: string }) {
  const location = useLocation();

  const isActive = location.pathname === href;
  return (
    <NL
      to={href}
      className={`flex items-center justify-center rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 ${className} ${isActive ? 'bg-slate-100 text-slate-900' : ''}`}
    >
      {children}
    </NL>
  );
}
