import { type ImageProps } from 'next/image';
import clsx from 'clsx';
import { Container } from '@/components/Container';

import backgroundImage from '@/images/background-faqs.jpg';

export interface FeatureT {
  name: React.ReactNode;
  summary?: string;
  description: string;
  image?: ImageProps['src'];
  icon: React.ComponentType;
}

function Feature({
  feature,
  isActive,
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'> & {
  feature: Feature;
  isActive: boolean;
}) {
  return (
    <div className={clsx(className, !isActive && 'opacity-75 hover:opacity-100')} {...props}>
      <div className={clsx('w-9 rounded-lg', isActive ? 'bg-blue-600' : 'bg-slate-500')}>
        <svg aria-hidden="true" className="size-9" fill="none">
          <feature.icon />
        </svg>
      </div>
      <h3 className={clsx('mt-6 text-xl font-medium', isActive ? 'text-blue-600' : 'text-slate-600')}>{feature.name}</h3>
      <p className="font-display mt-2 text-xl text-slate-900">{feature.summary}</p>
      <p className="mt-4 text-base text-slate-600">{feature.description}</p>
    </div>
  );
}

interface Props {
  features: FeatureT[];
}

export const Features = ({ features }: Props) => {
  return (
    <section
      id="features"
      aria-label="Features for simplifying everyday business tasks"
      className="relative py-14 pt-12 sm:pb-20 sm:pt-12 lg:pb-20"
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: '50% 25%' }}
    >
      <Container className="relative">
        <div className="">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Drive Success with Expert Solutions</h2>
          <div className="mt-16 sm:mt-20 lg:mt-24">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {features.map(feature => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="mb-6 flex size-12 items-center justify-center rounded-lg bg-blue-500">
                      <feature.icon className="size-7 text-white" aria-hidden="true" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-900">
                    <p className="flex-auto">{feature.description}</p>
                    {/* <p className="mt-6">
                        <a href={feature.href} className="text-sm font-semibold leading-6 text-indigo-400">
                          Learn more <span aria-hidden="true">→</span>
                        </a>
                      </p> */}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        {/* <FeaturesMobile />
        <FeaturesDesktop /> */}
      </Container>
    </section>
  );
};
